<style scoped lang="scss"></style>

<template>
  <div class="notice_list_box">
    <div class="toolbars mrgb5">
      <div class="buttons mrgb5">
        <div class="fl">
          <el-button class="mrgb5" type="primary" icon="el-icon-plus" @click="_edit()" size="medium">添加文章</el-button>
        </div>
        <div class="fr">
          <el-select class="mrgr5 mrgb5" v-model="filter.categoryId" placeholder="类型" @change="_search()" clearable size="medium">
            <el-option v-for="item in categories" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-input class="c-el-input mrgr5 mrgb5" placeholder="关键字" clearable v-model="filter.keywords"
            size="medium"></el-input>
          <el-button type="primary" size="medium" @click="_search()"><i class="fa fa-search"></i> 查询</el-button>
        </div>
        <div class="clearb"></div>
      </div>
    </div>
    <div>
      <el-table :data="tableData" border fit highlight-current-row row-key="id">
        <el-table-column prop="id" label="ID" width="200"></el-table-column>
        <el-table-column label="类别">
          <template slot-scope="scope">
            <el-tag type="success">{{ scope.row.category && scope.row.category.name }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="title" label="标题" width="200"></el-table-column>
        <el-table-column prop="url" label="链接"></el-table-column>
        <el-table-column prop="creationTime" label="创建时间" width="160"></el-table-column>
        <el-table-column label="操作" width="100px" fixed="right">
          <template slot-scope="scope">
            <el-dropdown trigger="click">
              <el-button type="primary" plain size="mini">更多
                <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="_edit(scope.row)">修改</el-dropdown-item>
                <el-dropdown-item @click.native="_delete(scope.row)">删除</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container" v-if="isPagination">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="filter.page" :page-sizes="appPageSizes" :page-size="filter.size"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
    <!--编辑-->
    <edit :ref="refEdit" @refreshData="_search()"></edit>
  </div>
</template>

<script>
import * as funCommon from "@/api/all"
import * as funArticle from "@/api/article/index"
import edit from "./components/edit"
export default {
  name: "pageArticleList",
  components: { edit },
  data() {
    return {
      refEdit: "refArticleListToEdit",
      tableItems: {},
      tableData: [],
      total: null,
      isPagination: false,
      filter: {
        page: 1,
        size: window.$common.appPageSize,
        keywords:"",
        categoryId: ""
      },
      categories: []
    }
  },
  activated() {
    this.getDataList()
  },
  created() {
    this.getDataList()
    this.renderCategory()
  },
  methods: {
    handleSizeChange(val) {
      this.filter.size = val
      this.getDataList()
    },
    handleCurrentChange(val) {
      this.filter.page = val
      this.getDataList()
    },
    async getDataList() {
      window.$common.fullLoading()
      funArticle.ArticleList(this.filter).then(response => {
        window.$common.closeFullLoading()
        this.tableItems = response
        this.total = response.totalCount
        this.isPagination = response.totalCount > 0 || false
        if (response && response.items && response.items.length > 0) {
          this.tableData = response.items
        } else {
          this.tableData = []
        }
      })
    },
    async renderCategory() {
      funCommon.CategoryAll({ type: 3 }).then(res => {
        this.categories = res
      })
    },
    _edit(row) {
      this.$refs[this.refEdit].show(row)
    },
    _delete(row) {
      this.confirm("确认要删除该文章吗?").then(() => {
        window.$common.fullLoading()
        funArticle.remove({ id: row.id }).then(res => {
          window.$common.closeFullLoading()
          this.successMsg("删除成功")
          this.getDataList()
        })
      })
    },
    _search() {
      this.filter.page = 1
      this.getDataList()
    },
  }
}
</script>