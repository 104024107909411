import request from '@/utils/request'

export function ArticleCreate(data) {
  return request({
    url: '/api/services/app/Article/Create',
    method: 'post',
    data
  })
}

export function QueryListByCateId(data) {
  return request({
    url: '/api/services/app/Article/QueryList',
    method: 'post',
    data
  })
}

export function ArticleList(data) {
  return request({
    url: '/api/services/app/Article/GetPagedList',
    method: 'get',
    params: data
  })
}

export function ArticleDetail(data) {
  return request({
    url: '/api/services/app/Article/Get',
    method: 'get',
    params: data
  })
}

export function ArticleUpdate(data) {
  return request({
    url: '/api/services/app/Article/Update',
    method: 'put',
    data
  })
}

export function remove(data) {
  return request({
    url: '/api/services/app/Article/Delete',
    method: 'delete',
    params: data
  })
}

export function removeMuliple(data) {
  return request({
    url: '/api/services/app/Article/Deletes',
    method: 'delete',
    params: data
  })
}
