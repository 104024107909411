<style lang="scss" scoped>
/deep/ .comp-article-edit {
    margin-top: 6vh !important;
    max-width: 80%;
}
</style>

<template>
    <div>
        <el-dialog :title="titlePrev + '文章'" custom-class="c-el-dialog comp-article-edit" :visible.sync="showDialog" :before-close="_close" :close-on-click-modal="false">
            <el-form :model="dialogData" :rules="formRules" :ref="formRefName" label-width="100px" size="small">
                <el-row :gutter="10">
                    <el-col :xs="24">
                        <el-form-item label="标题" prop="title">
                            <el-input v-model="dialogData.title" placeholder="请输入标题"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="类别" prop="type">
                            <el-select v-model="dialogData.categoryId" placeholder="请选择类别">
                                <el-option v-for="item in categories" :key="item.id" :label="item.name" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="内容" prop="content">
                            <ueditor :content="dialogData.content" @changeContent="changeContent"></ueditor>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer">
                <el-button size="small" @click="_close()">取消</el-button>
                <el-button size="small" type="primary" @click="_save()">提交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import configMixins from "@/mixins/config"
import enumConfigs from "@/mixins/enumConfigs"
import * as funCommon from "@/api/all"
import * as funArticle from "@/api/article/index"
import ueditor from '@/components/UEditor/Index'
export default {
    name: "compArticleEdit",
    mixins: [configMixins, enumConfigs],
    components: {
        ueditor
    },
    data() {
        return {
            showDialog: false,
            titlePrev: "",
            dialogType: "",
            defaultData: {
                id: 0,
                title: "",
                categoryId: "",
                content: ""
            },
            dialogData: {},
            formRefName: "refArticleEditForm",
            formRules: {
                title: [{ required: true, message: "请输入文章标题", trigger: "blur" }],
                categoryId: [{ required: true, message: "请选择类别", trigger: "change" }],
                content: [{ required: true, message: "请输入内容", trigger: ["blur", "change"] }]
            },
            categories: []
        }
    },
    methods: {
        show(row) {
            this.renderCategory()
            this.dialogData = JSON.parse(JSON.stringify(this.defaultData))
            this.dialogType = row ? this.createOrUpdate[1] : this.createOrUpdate[0]
            if (this.dialogType == this.createOrUpdate[0]) {
                // 新增
                this.titlePrev = "新增"
                this.showDialog = true
            } else {
                // 修改
                this.titlePrev = "修改"
                this.dialogData.id = row.id
                window.$common.fullLoading()
                funArticle.ArticleDetail({ id: this.dialogData.id }).then(res => {
                    window.$common.closeFullLoading()
                    for (let i in this.dialogData) {
                        this.dialogData[i] = res[i]
                    }
                    this.showDialog = true
                })
            }
        },
        async renderCategory() {
            funCommon.CategoryAll({ type: 3 }).then(res => {
                this.categories = res
            })
        },
        _save() {
            this.$refs[this.formRefName].validate((valid) => {
                if (valid) {
                    let commitData = JSON.parse(JSON.stringify(this.dialogData))
                    window.$common.fullLoading()
                    if (this.dialogType == this.createOrUpdate[0]) {
                        // 新增
                        funArticle.ArticleCreate(commitData).then(() => {
                            this.commonSuccess()
                        })
                    } else {
                        // 修改
                        funArticle.ArticleUpdate(commitData).then(() => {
                            this.commonSuccess()
                        })
                    }
                } else {
                    console.log('error submit!')
                    return false
                }
            })
        },
        changeContent(val) {
            this.dialogData.content = val
        },
        _close() {
            this.showDialog = false
            this.$nextTick(() => {
                if (this.$refs[this.formRefName]) {
                    this.$refs[this.formRefName].resetFields()
                }
            })
        },
        commonSuccess(val) {
            let tips = val || "操作成功!"
            this.successMsg(tips, {
                onClose: () => {
                    this._close()
                    window.$common.closeFullLoading()
                    this.refreshData()
                }
            })
        },
        refreshData() {
            this.$emit("refreshData", true)
        }
    }
}
</script>